import gql from 'graphql-tag'

export const getReservesSettings = gql`
  query reservesSettings {
    settings_process(where: {table_name: {_eq: "reserve"}}) {
      id
      config
    }
  }
`

export const getReserves = gql`
  query reserves($start_date: timestamp!, $end_date: timestamp!) {
    records: sales_reserve(where: {created_at: {_gte: $start_date, _lte: $end_date}}) {
      id
      closingReason: closing_reason {
        id
        description
        type {
          id
          name
          description
        }
      }
      createdAt: created_at
      status {
        id
        status {
          id
          name
          description
        }
      }
      saleOrder: sale_order {
        id
        deal {
          id
          lead {
            id
            executive {
              id
              person {
                firstName: first_name
                id
                secondName: second_name
                secondSurname: second_surname
                surname
              }
            }
          }
        }
      }
    }
  }
`
