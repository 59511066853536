import { Entity } from '..'
import { Type } from 'class-transformer'
import { Person } from '../persons'

export class User extends Entity {
  @Type(() => Person)
  person: Person;

  email
}
