import { GetterTree, MutationTree } from 'vuex'

import { State as RootState } from '@/store/state'
import { defaultCursor, Resource } from '@/entities/public'
import { Link } from '@/store/app/state'
import { Cursor, Filter } from '@/entities/public/Resource/interfaces'
import { Period, periods } from '@/store/resources/period'
import { Models } from '@/graphql/forms'
import { IForms } from '@/store/resources/form/state'

import { examples } from '@/entities/public/Resource/examples'
import { ComponentCategory } from '@/entities/vehicle'

export interface Resources {
  all: Array<Resource>
  links: Array<Link>
  forms: IForms
  active: Resource | any
  filter: Filter | any
  cursor: Cursor
  period: Period
  categories: Array<ComponentCategory>
}

export function initState (): Resources {
  return {
    all: [],
    links: [],
    forms: {},
    active: {},
    filter: {},
    cursor: { ...defaultCursor },
    period: periods[8],
    categories: [],
  }
}

export const mutations: MutationTree<Resources> = {
  setCategories: (state, categories) => state.categories = categories,
  clean: state => Object.assign(state, initState()),
  setAll: (state, all) => state.all = all,
  loadLinks: state => {
    const { all } = state
    if (!all) return state.links = []

    const routes: Record<string, Link> = {}

    all.filter(({ isView }) => isView).forEach(resource => {
      const { route: { name } } = resource
      if (!name || routes[name]) return

      const { parent, link } = resource
      routes[name] = parent || link
    })

    state.links = Object.entries(routes).map(([slug, value]) => {
      return { ...value, slug }
    }).sort((linkA, linkB) => linkA.id - linkB.id)
  },
  loadForms: state => {
    const { all } = state
    if (!all) return state.forms = {}

    const forms: IForms = {}

    examples.concat(all).filter(({ isForm }) => isForm).forEach(resource => {
      if (!resource.metadata) throw new Error(`Metadata is required for resource: ${resource.id}`)

      const { model } = resource.metadata
      const Model = Models[model]
      if (!Model) console.error(`Unimplemented Form Model: ${model}`)

      if (!forms[model]) forms[model] = {}
      Object.assign(forms[model], { [resource.slug]: resource })
    })

    state.forms = forms
  },
  setActive: (state, active) => state.active = active,
  setFilter: (state, filter) => state.filter = filter,
  setActiveCursor: state => {
    const { active, filter, cursor } = state
    state.cursor = active instanceof Resource ? active.cursor(filter) : cursor
  },
  setCursorSize: (state, size) => state.cursor.size = size,
  setCursorPage: (state, page) => state.cursor.page = page,
  setPeriod: (state, period) => state.period = period,
}

export const getters: GetterTree<Resources, RootState> = {
  all: ({ all }) => all,
  links: ({ links }) => links,
  forms: ({ forms }) => forms,
  active: ({ active }) => active,
  filter: ({ filter }) => filter,
  cursor: ({ cursor }) => cursor,
  period: ({ period }) => period,
  getResource: ({ all }) => (slug: string) => all.find(r => r.slug === slug),
  getForm: ({ forms }) => function getForm (model, slug) {
    const Model = forms[model]
    if (!Model) throw new Error(`Model ${model} is not present in forms`)

    if (!slug) slug = Object.keys(Model)[0]

    return Model[slug]
  },
  categories: ({ categories }) => categories,
}
