import gql from 'graphql-tag'

export const createLaborData = gql`
  mutation createLaborData($data: CreateLaborDataInput!) {
    createLaborData(createLaborDataInput: $data) {
      laborData {
        id
        rent
        jobTitle
        admissionDate
        jobKey
        employer {
          id
          uid
          companyName
          alias
          firstName
          secondName
          surname
          secondSurname
          personType {
            name
          }
        }
        person {
          id
          firstName
          surname
        }
        activityType {
          name
          description
        }
        contractType {
          name
          description
        }
        rentType {
          name
          description
        }
        active
      }
      exist
    }
  }`

export const personLaborUpdateStatus = gql`
  mutation personLaborUpdateStatus($id: Int!) {
    update_persons_labor_data(where: {id: {_eq: $id}}, _set: {active: false}) {
      affected_rows
    }
  }
`
export const updateLaborData = gql`
  mutation updateLaborInfo($id: Int!, $admissionDate: date!, $executiveComment: String!, $nameContractType: persons_contract_type_enum, $nameRentType: persons_rent_type_enum, $rent: numeric!, $jobKey: String) {
    updatePersonsLaborData: update_persons_labor_data(where: {id: {_eq: $id}}, _set: {admission_date: $admissionDate, executive_comment: $executiveComment, name_contract_type: $nameContractType, name_rent_type: $nameRentType, rent: $rent, job_key: $jobKey}) {
      affected_rows
      returning {
        id
        activityType: activity_type {
          description
          name
        }
        contractType: contract_type {
          description
          name
        }
        rentType: rent_type {
          description
          name
        }
        executiveComment: executive_comment
        nameRentType: name_rent_type
        nameContractType: name_contract_type
        nameActivityType: name_activity_type
        admissionDate: admission_date
        rent
      }
    }
  }
`
