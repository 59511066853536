import { Type } from 'class-transformer'
import { Entity } from '..'

import { PersonAddress, PropertyType, StreetType } from '.'
import { City } from '../public'

export class Address extends Entity {
  @Type(() => City)
  city: City;

  @Type(() => PropertyType)
  propertyType: PropertyType;

  @Type(() => StreetType)
  streetType: StreetType;

  @Type(() => PersonAddress)
  people: PersonAddress[];

  streetName: string;
  streetNumber: string;
  propertyNumber: string;

  toString () {
    const { streetName, streetNumber, city } = this
    return `${streetName} ${streetNumber}, ${city}`
  }
}
