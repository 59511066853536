import { Type } from 'class-transformer'
import { Entity } from '..'

import { Negotiation, NegotiationResponseType } from '.'
import { Employee } from '../hr'

export class NegotiationResponse extends Entity {
  @Type(() => Employee)
  responsible: Employee;

  @Type(() => Negotiation)
  negotiation: Negotiation;

  @Type(() => NegotiationResponseType)
  responseType: NegotiationResponseType;

  amount: number | null;

  get isAppealed () {
    const { responseType: { name } } = this
    return name === NegotiationResponseType.appealed
  }

  get isOffer () {
    const { responseType: { name } } = this
    return name === NegotiationResponseType.offer
  }

  get isOfferConsignment () {
    const { responseType: { name } } = this
    return name === NegotiationResponseType.offerConsignment
  }

  get isAppealedConsignment () {
    const { responseType: { name } } = this
    return name === NegotiationResponseType.appealedConsignment
  }
}
