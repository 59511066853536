import { PurchaseOrder } from '.'

export class Consignment extends PurchaseOrder {
  get utility () {
    const { agreedAmount, negotiation } = this

    if (!negotiation?.inspection?.enablement?.stock?.id) {
      return { value: Number(negotiation.inspection.appraisal.expectedPublicationAmount) - Number(agreedAmount) }
    }

    const expectedPublicationAmount = Number(negotiation.inspection.enablement.stock.viewDetails.expectedPublicationAmount) || 0
    const purchaseAmount = Number(negotiation.inspection.enablement.stock.viewDetails.purchaseAmount) || 0
    const enablementCost = Number(negotiation.inspection.enablement.stock?.viewDetails?.enablementCost) || 0
    const bono = Number(negotiation.inspection.enablement.stock?.price?.bonus?.amount) || 0

    return { value: expectedPublicationAmount - purchaseAmount - enablementCost - bono }
  }

  get agreementPrice () {
    const { agreedAmount, negotiation } = this
    if (!negotiation?.inspection?.enablement?.stock?.id) {
      return { value: agreedAmount }
    }
    const purchaseAmount = Number(negotiation.inspection.enablement.stock.viewDetails.purchaseAmount)
    return { value: purchaseAmount }
  }

  get stockLeadInformation () {
    const { negotiation } = this

    return negotiation.inspection.enablement?.stock?.received
  }

  get stockDaysInformation () {
    const { negotiation } = this
    return negotiation.inspection.enablement?.stock?.stockInformation
  }
}
