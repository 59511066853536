import { Actions } from '../../interfaces'
import { Deal } from '@/entities/crm'

import find from './find'
import fetch from './fetch'
import create from './create'
import update from './update'
import findLite from './findLite'

export const actions: Actions = {
  find,
  fetch,
  create,
  update,
  findLite,
}

export default {
  Model: { Deal },
  actions: { [Deal.name]: actions },
}
