import gql from 'graphql-tag'

export const query = gql`
  query findInspectionView($filter: purchase_inspection_detail_view_bool_exp){
    records: purchase_inspection_detail_view(where: $filter) {
      idInspectedComponent:id_inspected_component
      date
      idAuto:id_auto
      component{
        id
        inspectionComponent:inspection_component {
          id
          component {
            category {
              name
            }
            id
            name
            slug
          }
          componentValue:component_value {
            id
            value
          }
          config
        }
      }
      parameter {
        order
        name
      }
      assessment {
        option {
          name
        }
      }
      value:qualification_value
    }
  }
`

export default query
