import { Metadata } from '.'
import { Metadata as IMetadata, Filter, Query, Aggregate, Forms } from '../interfaces'

export class View extends Metadata implements Omit<IMetadata, '__type'> {
    aggregates: Array<Aggregate>;
    filters: Array<Filter>;
    query: Query;
    forms: Forms;
    search: string[];

    get model (): string {
      const { query: { model } } = this
      return model
    }
}
