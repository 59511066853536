import gql from 'graphql-tag'

export const getDailyStock = gql`
  query daily_stock ($model: sales_stock_daily_bool_exp) {
    sales_stock_daily (where: $model){
      id
      report_date
      stock {
        id
        stock_detail {
          total_cost
        }
      }
      process_status {
        status {
          name
          description
          color
        }
      }
    }
  }
`

export const getSalesData = gql`
  query get_effective_sales_report_by_date($start_date: String!, $end_date: String!) {
    sales: get_effective_sales_report_by_date(start_date: $start_date, end_date: $end_date)
  }
`

export const getSalesTarget = gql`
  {
    settings_process(where: {table_name: {_eq: "sale_order"}})
    {
      config
    }
  }
`

export const getPurchasesTarget = gql`
  {
    settings_process(where: {table_name: {_eq: "purchase_order"}})
    {
      config
    }
  }
`

export const getSalesAmountAggregate = gql`
  query utilitySales( $aggregate: sales_sale_order_item_bool_exp) {
    sales_sale_order_item_aggregate(where: $aggregate) {
      aggregate {
        sum {
          amount
        }
      }
      nodes {
        id
        amount
        saleOrder: sale_order {
          id
          deal {
            id
            stock {
              id
              soldDate: sold_date
            }
          }
        }
      }
    }
  }
`

export const getPurchasesAmountAggregate = gql`
  query purchases($aggregate: sales_stock_view_bool_exp) {
    sales_stock_view_aggregate(where: $aggregate) {
      aggregate {
        count
        sum {
          total_cost
        }
      }
      nodes {
        stock_created
        totalCost: total_cost
        stock {
          soldDate: sold_date
        }
        channel {
          id
          name
          description
          color
        }
        executive {
          id
          person {
            id
            first_name
            surname
          }
        }
        auto {
          registration_plate
          version_year {
            version {
              model {
                name
                brand {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`

export const getStockSpecs = gql`
  query stockSpecs($model: sales_stock_view_bool_exp) {
    salesStockView: sales_stock_view(where: $model) {
      stock {
        prices(limit: 1, order_by: {id: desc}) {
          amount
        }
      }
      status {
        status {
          id
          description
          name
        }
      }
      auto {
        id
        generation {
          id
        }
        version: version_year {
            id
          version {
            id
          }
          year {
            id
          }
        }
        registrationPlate: registration_plate
      }
    }
  }
`

export const getStockStatusAggregate = gql`
  query aggregates($model: settings_process_status_bool_exp, $aggregate: sales_stock_view_bool_exp, $order: [settings_process_status_order_by!]) {
    aggregates: settings_process_status(where: $model, order_by: $order) {
      status {
        id
        description
        color
      }
      stocks: stock_view_aggregate(where: $aggregate) {
        aggregate {
          count(distinct: true)
        }
      }
    }
  }
`

export const getStockPricesAggregate = gql`
  query aggregates($model: settings_process_status_bool_exp, $aggregate: sales_stock_view_bool_exp, $order: [settings_process_status_order_by!]) {
    aggregates: settings_process_status(where: $model, order_by: $order) {
      status {
        id
        description
        color
      }
      stocks: stock_view_aggregate(where: $aggregate) {
        aggregate {
          sum {
            total_cost
          }
        }
      }
    }
  }
`

export const dailyStockAggregate = gql`
  query aggregates($model: settings_process_status_bool_exp, $aggregate: sales_stock_view_bool_exp, $order: [settings_process_status_order_by!]) {
    aggregates: settings_process_status(where: $model, order_by: $order) {
      status {
        id
        description
      }
      stocks: stock_view_aggregate(where: $aggregate) {
        nodes {
          created:stock_created
          cost:total_cost
        }
      }
    }
  }
`
