import { ActionTree } from 'vuex'
import { State as RootState } from '@/store/state'
import { Dashboard } from '@/store/dashboards/state'
import {
  dailyStockAggregate,
  getDailyStock,
  getPurchasesAmountAggregate,
  getPurchasesTarget,
  getSalesAmountAggregate,
  getSalesData,
  getSalesTarget,
  getStockPricesAggregate,
  getStockSpecs,
  getStockStatusAggregate,
} from '@/graphql/customQueries/dashboard/sales/stock'
import {
  getLeadByExecutiveAggregate,
  getLeadsAggregate,
  getPurchasesByDayAggregate,
  getSalesByDayAggregate,
} from '@/graphql/customQueries/dashboard/crm/lead'
import { getDealByChannelAggregate } from '@/graphql/customQueries/dashboard/crm/deal'
import { getClosingReasonAggregate } from '@/graphql/customQueries/dashboard/settings/closingReason'
import {
  getCreditsByDay,
  getCreditsTarget,
  getLoanEvaluations,
  getSignedLoans,
  getUtilityCreditsByDay,
} from '@/graphql/customQueries/dashboard/credits'
import { findDashboardFinancing } from '@/graphql/customQueries/dashboard/loans/financing/financing'
import { plainToInstance } from 'class-transformer'
import { Financing } from '@/entities/loans'
import { getExecutivesForReports } from '@/graphql/customQueries/dashboard/hr/employee'
import {
  getCreditsReports,
  getDeliveryStatus,
  getFinancialReports,
  getInspectionReports,
  getInspectionsStatus,
  getPurchasesReport,
  getReserveReports,
  getSalesReports,
} from '@/graphql/customQueries/dashboard/reports'
import { inspectionsSettings } from '@/graphql/customQueries/dashboard/purchase/inspection'
import { getBusinessTypes } from '@/graphql/customQueries/dashboard/crm/pipeline'
import { getInspections } from '@/graphql/customQueries/purchase/inspection/inspection'
import { getReserves, getReservesSettings } from '@/graphql/customQueries/dashboard/sales/reserve'

export const actions: ActionTree<Dashboard, RootState> = {
  getStockStatus: async (
    { rootState: { apolloClient } },
    variables
  ): Promise<void> => {
    if (!apolloClient) return

    try {
      const { data } = await apolloClient.query({
        query: getStockStatusAggregate,
        fetchPolicy: 'network-only',
        variables,
      })

      return data
    } catch (e) {
      console.error(e)
    }
  },
  getBusinessTypes: async (
    { rootState: { apolloClient } },
  ): Promise<void> => {
    if (!apolloClient) return

    try {
      const { data } = await apolloClient.query({
        query: getBusinessTypes,
        fetchPolicy: 'network-only',
      })

      return data
    } catch (e) {
      console.error(e)
    }
  },
  getStockPrices: async (
    { rootState: { apolloClient } },
    variables
  ): Promise<void> => {
    if (!apolloClient) return

    try {
      const { data } = await apolloClient.query({
        query: getStockPricesAggregate,
        fetchPolicy: 'network-only',
        variables,
      })

      return data
    } catch (e) {
      console.error(e)
    }
  },
  getDeliveryStatus: async (
    { rootState: { apolloClient } },
    variables
  ): Promise<void> => {
    if (!apolloClient) return

    try {
      const { data } = await apolloClient.query({
        query: getDeliveryStatus,
        fetchPolicy: 'network-only',
        variables,
      })

      return data
    } catch (e) {
      console.error(e)
    }
  },
  getLeadByExecutive: async (
    { rootState: { apolloClient } },
    variables
  ): Promise<void> => {
    if (!apolloClient) return

    try {
      const { data } = await apolloClient.query({
        query: getLeadByExecutiveAggregate,
        fetchPolicy: 'network-only',
        variables,
      })

      return data
    } catch (e) {
      console.error(e)
    }
  },
  getSalesTarget: async (
    { rootState: { apolloClient } }
  ): Promise<void> => {
    if (!apolloClient) return

    try {
      const { data } = await apolloClient.query({
        query: getSalesTarget,
        fetchPolicy: 'network-only',
      })

      return data
    } catch (e) {
      console.error(e)
    }
  },

  getFinancialReports: async (
    { rootState: { apolloClient } }
  ): Promise<void> => {
    if (!apolloClient) return

    try {
      const { data } = await apolloClient.query({
        query: getFinancialReports,
        fetchPolicy: 'network-only',
      })

      return data
    } catch (e) {
      console.error(e)
    }
  },

  getInspectionReports: async (
    { rootState: { apolloClient } }
  ): Promise<void> => {
    if (!apolloClient) return

    try {
      const { data } = await apolloClient.query({
        query: getInspectionReports,
        fetchPolicy: 'network-only',
      })

      return data
    } catch (e) {
      console.error(e)
    }
  },

  getReserveReports: async (
    { rootState: { apolloClient } }
  ): Promise<void> => {
    if (!apolloClient) return

    try {
      const { data } = await apolloClient.query({
        query: getReserveReports,
        fetchPolicy: 'network-only',
      })

      return data
    } catch (e) {
      console.error(e)
    }
  },

  getSalesReports: async (
    { rootState: { apolloClient } }
  ): Promise<void> => {
    if (!apolloClient) return

    try {
      const { data } = await apolloClient.query({
        query: getSalesReports,
        fetchPolicy: 'network-only',
      })

      return data
    } catch (e) {
      console.error(e)
    }
  },

  getCreditsReports: async (
    { rootState: { apolloClient } }
  ): Promise<void> => {
    if (!apolloClient) return

    try {
      const { data } = await apolloClient.query({
        query: getCreditsReports,
        fetchPolicy: 'network-only',
      })

      return data
    } catch (e) {
      console.error(e)
    }
  },

  getPurchasesReports: async (
    { rootState: { apolloClient } }
  ): Promise<void> => {
    if (!apolloClient) return

    try {
      const { data } = await apolloClient.query({
        query: getPurchasesReport,
        fetchPolicy: 'network-only',
      })

      return data
    } catch (e) {
      console.error(e)
    }
  },

  getPurchasesTarget: async (
    { rootState: { apolloClient } }
  ): Promise<void> => {
    if (!apolloClient) return

    try {
      const { data } = await apolloClient.query({
        query: getPurchasesTarget,
        fetchPolicy: 'network-only',
      })

      return data
    } catch (e) {
      console.error(e)
    }
  },

  getCreditsTarget: async (
    { rootState: { apolloClient } }
  ): Promise<void> => {
    if (!apolloClient) return

    try {
      const { data } = await apolloClient.query({
        query: getCreditsTarget,
        fetchPolicy: 'network-only',
      })

      return data
    } catch (e) {
      console.error(e)
    }
  },

  getUtilityCreditsByDay: async (
    { rootState: { apolloClient } },
    variables
  ): Promise<void> => {
    if (!apolloClient) return

    try {
      const { data } = await apolloClient.query({
        query: getUtilityCreditsByDay,
        fetchPolicy: 'network-only',
        variables,
      })

      return data
    } catch (e) {
      console.error(e)
    }
  },
  getReserves: async (
    { rootState: { apolloClient } },
    variables
  ): Promise<void> => {
    if (!apolloClient) return

    try {
      const { data } = await apolloClient.query({
        query: getReserves,
        fetchPolicy: 'network-only',
        variables,
      })

      return data
    } catch (e) {
      console.error(e)
    }
  },

  getSalesAmount: async (
    { rootState: { apolloClient } },
    variables
  ): Promise<void> => {
    if (!apolloClient) return

    try {
      const { data } = await apolloClient.query({
        query: getSalesAmountAggregate,
        fetchPolicy: 'network-only',
        variables,
      })

      return data
    } catch (e) {
      console.error(e)
    }
  },

  getInspections: async (
    { rootState: { apolloClient } },
    variables
  ): Promise<void> => {
    if (!apolloClient) return

    try {
      const { data } = await apolloClient.query({
        query: getInspections,
        fetchPolicy: 'network-only',
        variables,
      })

      return data
    } catch (e) {
      console.error(e)
    }
  },

  getPurchasesAmount: async (
    { rootState: { apolloClient } },
    variables
  ): Promise<void> => {
    if (!apolloClient) return

    try {
      const { data } = await apolloClient.query({
        query: getPurchasesAmountAggregate,
        fetchPolicy: 'network-only',
        variables,
      })

      return data
    } catch (e) {
      console.error(e)
    }
  },

  getCreditsByDay: async (
    { rootState: { apolloClient } },
    variables
  ): Promise<void> => {
    if (!apolloClient) return

    try {
      const { data } = await apolloClient.query({
        query: getCreditsByDay,
        fetchPolicy: 'network-only',
        variables,
      })

      return data
    } catch (e) {
      console.error(e)
    }
  },

  getStockSpecs: async (
    { rootState: { apolloClient } },
    variables
  ): Promise<void> => {
    if (!apolloClient) return

    try {
      const { data } = await apolloClient.query({
        query: getStockSpecs,
        fetchPolicy: 'network-only',
        variables,
      })

      return data
    } catch (e) {
      console.error(e)
    }
  },

  getSignedLoans: async (
    { rootState: { apolloClient } },
    variables
  ): Promise<void> => {
    if (!apolloClient) return

    try {
      const { data } = await apolloClient.query({
        query: getSignedLoans,
        fetchPolicy: 'network-only',
        variables,
      })

      return data
    } catch (e) {
      console.error(e)
    }
  },

  getLoanEvaluations: async (
    { rootState: { apolloClient } },
    variables
  ): Promise<void> => {
    if (!apolloClient) return

    try {
      const { data } = await apolloClient.query({
        query: getLoanEvaluations,
        fetchPolicy: 'network-only',
        variables,
      })

      return data
    } catch (e) {
      console.error(e)
    }
  },

  getSalesByDay: async (
    { rootState: { apolloClient } },
    variables
  ): Promise<void> => {
    if (!apolloClient) return

    try {
      const { data } = await apolloClient.query({
        query: getSalesByDayAggregate,
        fetchPolicy: 'network-only',
        variables,
      })

      return data
    } catch (e) {
      console.error(e)
    }
  },

  getPurchasesByDay: async (
    { rootState: { apolloClient } },
    variables
  ): Promise<void> => {
    if (!apolloClient) return

    try {
      const { data } = await apolloClient.query({
        query: getPurchasesByDayAggregate,
        fetchPolicy: 'network-only',
        variables,
      })

      return data
    } catch (e) {
      console.error(e)
    }
  },

  getLeads: async (
    { rootState: { apolloClient } },
    variables
  ): Promise<void> => {
    if (!apolloClient) return

    let returnData
    try {
      const { data } = await apolloClient.query({
        query: getLeadsAggregate,
        // fetchPolicy: 'network-only',
        variables,
      })
      returnData = data
    } catch (_e) {
      return returnData
    }
    return returnData
  },

  getDailyStock: async (
    { rootState: { apolloClient } },
    variables
  ): Promise<void> => {
    if (!apolloClient) return
    try {
      const { data } = await apolloClient.query({
        query: getDailyStock,
        fetchPolicy: 'network-only',
        variables,
      })

      return data
    } catch (e) {
      console.error(e)
    }
  },

  getDealsByChannel: async (
    { rootState: { apolloClient } },
    variables
  ): Promise<void> => {
    if (!apolloClient) return

    try {
      const { data } = await apolloClient.query({
        query: getDealByChannelAggregate,
        fetchPolicy: 'network-only',
        variables,
      })

      return data
    } catch (e) {
      console.error(e)
    }
  },
  getClosingReason: async (
    { rootState: { apolloClient } },
    variables
  ): Promise<void> => {
    if (!apolloClient) return

    try {
      const { data } = await apolloClient.query({
        query: getClosingReasonAggregate,
        fetchPolicy: 'network-only',
        variables,
      })

      return data
    } catch (e) {
      console.error(e)
    }
  },
  dailyStock: async (
    { rootState: { apolloClient } },
    variables
  ): Promise<void> => {
    if (!apolloClient) return

    try {
      const { data } = await apolloClient.query({
        query: dailyStockAggregate,
        fetchPolicy: 'network-only',
        variables,
      })

      return data
    } catch (e) {
      console.error(e)
    }
  },
  getFinancing: async (
    { rootState: { apolloClient } },
    variables
  ): Promise<void> => {
    if (!apolloClient) return

    try {
      const { data: { records } } = await apolloClient.query({
        query: findDashboardFinancing,
        fetchPolicy: 'network-only',
        variables,
      })

      return records.map(record => plainToInstance(Financing, record))
    } catch (e) {
      console.error(e)
    }
  },

  getInspectionsSettings: async (
    { rootState: { apolloClient } },
  ): Promise<void> => {
    if (!apolloClient) return

    try {
      const { data } = await apolloClient.query({
        query: inspectionsSettings,
        fetchPolicy: 'network-only',
      })

      return data
    } catch (e) {
      console.error(e)
    }
  },
  getInspectionsStatus: async (
    { rootState: { apolloClient } },
    variables
  ): Promise<void> => {
    if (!apolloClient) return

    try {
      const { data } = await apolloClient.query({
        query: getInspectionsStatus,
        fetchPolicy: 'network-only',
        variables,
      })

      return data
    } catch (e) {
      console.error(e)
    }
  },
  getSalesData: async (
    { rootState: { apolloClient } },
    variables
  ): Promise<void> => {
    if (!apolloClient) return

    try {
      const { data } = await apolloClient.query({
        query: getSalesData,
        fetchPolicy: 'network-only',
        variables,
      })

      return data
    } catch (e) {
      console.error(e)
    }
  },
  getExecutivesForReports: async (
    { rootState: { apolloClient } },
  ): Promise<void> => {
    if (!apolloClient) return

    try {
      const { data } = await apolloClient.query({
        query: getExecutivesForReports,
        fetchPolicy: 'network-only',
      })

      return data
    } catch (e) {
      console.error(e)
    }
  },
  getReservesSettings: async (
    { rootState: { apolloClient } },
  ): Promise<void> => {
    if (!apolloClient) return

    try {
      const { data } = await apolloClient.query({
        query: getReservesSettings,
        fetchPolicy: 'network-only',
      })

      return data
    } catch (e) {
      console.error(e)
    }
  },
}
