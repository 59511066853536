import gql from 'graphql-tag'

import { PaymentOrderFragment, PaymentOrderItemFragment, PaymentRecipientFragment } from '@/graphql/fragments/finance'

export const query = gql`
  ${PaymentRecipientFragment}
  ${PaymentOrderItemFragment}
  ${PaymentOrderFragment}
  ${PaymentRecipientFragment}
  query fetchPaymentRecipient($id: Int!) {
    records: finance_payment_recipient_by_pk(id: $id) {
      ...PaymentRecipient
      paymentOrderItem:payment_order_item {
        ...PaymentOrderItem
        order {
          ...PaymentOrder
        }
        recipients{
          ...PaymentRecipient
        }
      }
    }
  }
`

export default query
